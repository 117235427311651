// i18next-extract-mark-ns-start products-engdyn-noise

import { ENGDYN_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductEngdynPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation();

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="narrow"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content">
						<p>
							ENGDYN incorporates an advanced noise, vibration and
							harshness (NVH) post-processor, which allows the user to
							include the ENGDYN solution onto a 3D finite element
							analysis (FEA) model of the powertrain or ancillary
							components to predict nodal vibrations in the structure.
							These vibrations can be used to predict stress due to
							the dynamic loading and as inputs to the Rayleigh
							integral method or boundary element method (BEM)
							acoustic solvers.
						</p>
						<h2>Specific noise and vibration applications</h2>
						<ul>
							<li>Vibration analysis</li>
							<li>Vibrational stressing of powertrain components</li>
							<li>Engine mount structural analysis</li>
							<li>Radiated noise predictions</li>
						</ul>
						<h2>Key features</h2>
						<ul>
							<li>Integrated NVH post-processor</li>
							<li>Automated analysis set up and control</li>
							<li>Integrated FEA interface</li>
							<li>
								Ability to work with any major commercial FE solver
							</li>
							<li>
								Uses Rayleigh and BEM methods for radiated noise
								calculations
							</li>
							<li>Post-processing</li>
							<li>Structural attenuation</li>
							<li>Order plots and Campbell diagrams</li>
							<li>3D contour plots</li>
							<li>Animation of modes and displacements</li>
							<li>Sound intensity and power</li>
							<li>Sound pressure</li>
						</ul>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_Applications")}
						className="widget__sibling__pages">
						<AssetList>
							{ENGDYN_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
				</Aside>
			</PageSection>
		</Page>
	);
};

export default ProductEngdynPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-engdyn-noise", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/engdyn-hero.jpg" }) {
		...imageBreaker
	}
}
`;
